<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="indigo lighten-5" flat>
        <BackButton />
        <v-toolbar-title>{{ getDeviceTitle(device.type) }}{{ device.id }} {{ getMachineDesc(device.type) }}</v-toolbar-title>
        <v-chip v-if="getStatus" class="ml-3" :text-color="getStatus.color" small>
          <v-avatar left>
            <v-icon :color="getStatus.color" size="20">{{ getStatus.icon }}</v-icon>
          </v-avatar>
          <span class="text-caption">{{ getStatus.title }}</span>
        </v-chip>
        <v-spacer></v-spacer>
        <div class="font-italic font-weight-medium">Картка автомата</div>

        <template v-slot:extension>
          <v-tabs v-model="currentTab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab v-for="(item, index) in tabs" :key="index">
              {{ item.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="currentTab">
        <v-tab-item v-for="(item, index) in tabs" :key="index">
          <v-card flat color="blue-grey lighten-5">
            <component
              v-if="isDeviceExist"
              :is="item.component"
              :device="device"
              :isDataSaved="isDataSaved"
              :currentTab="currentTab"
              :isLoaded="isLoaded"
              :editCard="editCard"
              @saveData="saveData"
            ></component>
            <v-skeleton-loader v-else type="card, article"></v-skeleton-loader>
            <v-container v-show="showEditButtons" fluid>
              <div class="d-flex justify-end">
                <v-btn @click="editCard = !editCard" class="mr-2" small>
                  {{ !editCard ? 'Редагувати' : 'Повернутись' }}
                </v-btn>
                <v-btn v-show="editCard" @click="showModal = true" color="primary" small>Зберегти</v-btn>
              </div>
            </v-container>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-card v-if="!userRoles.includes(rolesDictionary.ACCOUNTANT)" class="mt-5" flat>
      <v-toolbar tile elevation="1">
        <v-tabs v-model="logTab">
          <v-tab
            v-for="tab in LOG_TABS"
            :key="tab.name"
            @click="$router.replace({ query: { logTab: tab.name } })"
            :tab-value="tab.name"
          >
            {{ tab.label }}
          </v-tab>
        </v-tabs>
      </v-toolbar>
      <Logs :instanceId="+currentDeviceId" />
    </v-card>

    <ConfirmActionModal @closeModal="showModal = false" :showModal="showModal">
      <template v-slot:title>Зберегти зміни?</template>
      <template v-slot:text>Дані будуть оновлені</template>
      <template v-slot:buttons>
        <v-btn @click="isDataSaved = true" color="primary" text>Так</v-btn>
        <v-btn @click="showModal = false" text>Нi</v-btn>
      </template>
    </ConfirmActionModal>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import convertMachinesTypes from '@/mixins/convertDevicesTypes'
import ConvertStatusesTypes from '@/mixins/convertStatusesTypes'
import SetLogTabs from '@/mixins/setLogTabs'
import { mergeObjectsDeep } from '@/helpers/mergeObjects'
import { DEVICE_SCHEMA } from '@/const/apiSchemas'
import BackButton from '@/components/common/BackButton.vue'

export default {
  name: 'DeviceCard',

  mixins: [convertMachinesTypes, ConvertStatusesTypes, SetLogTabs],

  components: {
    BackButton,
    ConfirmActionModal: () => import('@/components/dialogs/ConfirmActionModal'),
    Online: () => import('@/components/administration/device/Online'),
    GeneralInfo: () => import('@/components/administration/device/GeneralInfo'),
    Goods: () => import('@/components/administration/device/Goods.vue'),
    Logs: () => import('@/components/common/Logs'),
  },

  data() {
    return {
      showModal: false,
      currentTab: 1,
      isDataSaved: false,
      isLoaded: false,
      editCard: false,
      defaultItem: { ...DEVICE_SCHEMA },
      tabs: [
        {
          title: 'Online',
          component: 'Online',
        },
        {
          title: 'інфо',
          component: 'GeneralInfo',
        },
        {
          title: 'Товари',
          component: 'Goods',
        },
      ],
    }
  },

  computed: {
    ...mapState('devices', ['device']),
    ...mapState('dictionaries', ['machinesTypesDictionary']),

    currentCompanyId() {
      return this.$route.params.id
    },
    currentDeviceId() {
      return this.$route.params.deviceId
    },
    isDeviceExist() {
      return !!Object.entries(this.device).length
    },
    showEditButtons() {
      return this.currentTab === 1
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    ...mapActions('devices', ['updateSelectedDevice', 'loadSelectedDevice']),
    ...mapActions('logs', ['displayWarningAlert']),

    async initialize() {
      if (this.currentCompanyId !== 'undefined') {
        const payload = {
          companyId: this.currentCompanyId,
          deviceId: this.currentDeviceId,
        }
        await this.loadSelectedDevice(payload)
        this.isLoaded = true
      } else {
        await this.displayWarningAlert({ message: 'Оберiть компанiю' })
        await this.$router.replace('/spa')
      }
      this.logTab = this.$route.query?.logTab || this.LOG_TABS[0].name
    },

    async saveData(device) {
      const updatedDevice = mergeObjectsDeep(this.defaultItem, device)
      const payloadForUpdate = {
        companyId: this.currentCompanyId,
        deviceId: this.currentDeviceId,
        updatedDevice,
      }
      /*const payloadForLoad = {
        companyId: this.currentCompanyId,
        deviceId: this.currentDeviceId,
      }*/
      this.showModal = false
      this.isLoaded = false
      await this.updateSelectedDevice(payloadForUpdate)
      // await this.loadSelectedDevice(payloadForLoad)
      this.isDataSaved = false
      this.isLoaded = true
      this.editCard = false
    },
  },
}
</script>

<style scoped></style>
